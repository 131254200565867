.parentContainer {
    padding-bottom: clamp(0px, 2.5vw, 50px);
    background-color: #ebebeb;
    background-repeat: no-repeat;
    background-position: left 75%, right 75%;
    background-size: 16%, 14%;
}

.mainContainer {
    font-family: var(--font-primary);
    padding: 18px 10px;
    position: relative;
}

.upperQuoteImageContainer {
    position: absolute;
    width: clamp(60px, 4.16vw, 80px);
    height: clamp(50px, 3.64vw, 70px);
    top: 17%;
    left: 10%;
    transform: rotate(180deg);
}

.lowerQuoteImageContainer {
    position: absolute;
    width: clamp(80px, 5.7vw, 110px);
    height: clamp(70px, 5.2vw, 100px);
    top: 93%;
    left: 85%;
}

.upperQuoteImage {
    position: relative;
    width: 100%;
    height: 100%;
}

.lowerQuoteImage {
    position: relative;
    width: 100%;
    height: 100%;
}

.headingParent {
    display: flex;
    justify-content: center;
}

.heading {
    font-size: clamp(16px, 3vw, 60px);
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2.25px;
    text-align: center;
    color: #35255a;
    max-width: 65vw;
}

.sliderContainer {
    margin-top: 30px;
    height: clamp(250px, 26vw, 500px);
    display: flex;
    align-items: center;
}

.slideParentContainer {
    height: clamp(350px, 26vw, 500px);
    display: flex !important;
    align-items: center;
    position: relative;
    z-index: 0;
}

.slideBorderContainer {
    position: relative;
    border-radius: 21px;
    padding: 2px;
    clip-path: polygon(0 0, calc(100% - 35px) 0, 100% 30px, 100% 100%, 0 100%);
    transition: all 0.7s ease;
}

.slide {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: clamp(210px, 13vw, 250px);
    border-radius: 19px;
    background: #ffffff;
    padding: clamp(18px, 1.5vw, 30px) clamp(14px, 1vw, 20px);
    clip-path: polygon(0 0, calc(100% - 35px) 0, 100% 30px, 100% 100%, 0 100%);
}

.userImgContainer {
    width: 30%;
    height: 100%;
    position: relative;
    display: inline-block;
    border-radius: 12px;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
}

.userImgContainer img {
    border-radius: 12px;
}

.userDetails {
    width: 70%;
    height: 40%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.userName {
    font-size: 17px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.17px;
    text-align: left;
}

.amountWon {
    background: linear-gradient(85deg, rgba(229, 175, 70, 1) 0%, rgba(250, 132, 21, 1) 100%);
    font-size: clamp(11px, 1.14vw, 22px);
    font-weight: 900;
    font-style: italic;
    line-height: normal;
    letter-spacing: -0.28px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.userDescription {
    display: flex;
    width: 70%;
    height: 60%;
    padding: 0px 15px;
    justify-content: center;
    overflow-y: scroll;
    font-size: clamp(11px, 0.72vw, 14px);
    letter-spacing: -0.13px;
    opacity: 0.7;
    color: rgba(0, 0, 0, 0.87);
    line-height: 16px;
}

@media screen and (max-width: 1200px) {
    .parentContainer {
        background-image: none;
    }

    .userName {
        font-size: 12px;
    }

    .amountWon {
        font-size: 14px;
    }

    .userDescription {
        line-height: 17px;
    }
}

@media screen and (max-width: 576px) {
    .parentContainer {
        padding: 0;
    }

    .mainContainer {
        padding: 10px 0px 0px;
    }

    .upperQuoteImageContainer {
        display: none;
    }

    .lowerQuoteImageContainer {
        display: none;
    }

    .heading {
        line-height: 0.67;
        letter-spacing: 1px;
    }

    .userImgContainer {
        width: 25%;
        height: 50%;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%);
    }

    .userDetails {
        width: 75%;
        height: 50%;
    }

    .userDescription {
        width: 100%;
        height: 50%;
        padding: 10px 0px;
        line-height: normal;
        font-size: clamp(13px, 1vw, 20px);
    }

    .sliderContainer {
        margin-top: 0px;
    }

    .slideBorderContainer {
        clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 25px, 100% 100%, 0 100%);
    }

    .slide {
        flex-direction: row;
        clip-path: polygon(0 0, calc(100% - 30px) 0, 100% 25px, 100% 100%, 0 100%);
    }

    .slideParentContainer {
        width: 100%;
        padding: 10px;
    }

    .userName {
        font-size: 14px;
    }

    .amountWon {
        font-size: 16px;
    }
}